import locale77427e78 from '../../lang/en.js'
import locale77fcde9e from '../../lang/ru.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en","silentTranslationWarn":true,"numberFormats":{"en":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"ru":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}}},"dateTimeFormats":{"en":{"tickets":{"year":"numeric","month":"2-digit","day":"2-digit","hour":"2-digit","minute":"2-digit","hour12":false},"short":{"year":"numeric","month":"short","day":"numeric"},"shortExtended":{"year":"numeric","month":"long","day":"numeric"},"long":{"year":"numeric","month":"long","day":"numeric","weekday":"long","hour":"numeric","minute":"numeric"},"longer":{"year":"numeric","month":"long","day":"numeric","hour":"numeric","minute":"numeric"},"fullDay":{"weekday":"long"},"newsHeading":{"month":"short","day":"numeric"},"shortMonth":{"month":"short"}},"ru":{"tickets":{"year":"numeric","month":"2-digit","day":"2-digit","hour":"2-digit","minute":"2-digit","hour12":false},"short":{"year":"numeric","month":"short","day":"numeric"},"shortExtended":{"year":"numeric","month":"long","day":"numeric"},"long":{"year":"numeric","month":"long","day":"numeric","weekday":"long","hour":"numeric","minute":"numeric"},"longer":{"year":"numeric","month":"long","day":"numeric","hour":"numeric","minute":"numeric"},"fullDay":{"weekday":"long"},"newsHeading":{"month":"short","day":"numeric"},"shortMonth":{"month":"short"}}}},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-GB","file":"en.js"},{"code":"ru","iso":"ru-RU","file":"ru.js"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: false,
  langDir: "/home/ticketshoplv/public_html/nervy.tour.ticketshop/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "https://widget.ticketshop.store",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en-GB","file":"en.js"},{"code":"ru","iso":"ru-RU","file":"ru.js"}],
  localeCodes: ["en","ru"],
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'ru.js': () => Promise.resolve(locale77fcde9e),
}
