import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ea46e344 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _486be344 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _7294e47a = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _61ab56ca = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _9d1c1300 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _54843175 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _edd9f4a4 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _17590195 = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _53a86978 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _01c87747 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _075e981d = () => interopDefault(import('../pages/restore/index.vue' /* webpackChunkName: "pages/restore/index" */))
const _7c7416cc = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _90cf942a = () => interopDefault(import('../pages/widget/index.vue' /* webpackChunkName: "pages/widget/index" */))
const _10ad998d = () => interopDefault(import('../pages/profile/tickets.vue' /* webpackChunkName: "pages/profile/tickets" */))
const _c6b2f156 = () => interopDefault(import('../pages/widget/description.vue' /* webpackChunkName: "pages/widget/description" */))
const _50f6a8a1 = () => interopDefault(import('../pages/categories/_cid/index.vue' /* webpackChunkName: "pages/categories/_cid/index" */))
const _53453b06 = () => interopDefault(import('../pages/events/_eid/index.vue' /* webpackChunkName: "pages/events/_eid/index" */))
const _6dc6a6a3 = () => interopDefault(import('../pages/groups/_id.vue' /* webpackChunkName: "pages/groups/_id" */))
const _55b54d30 = () => interopDefault(import('../pages/payment/_hash.vue' /* webpackChunkName: "pages/payment/_hash" */))
const _9f798fb6 = () => interopDefault(import('../pages/restore/_key.vue' /* webpackChunkName: "pages/restore/_key" */))
const _c74780ca = () => interopDefault(import('../pages/events/_eid/tickets.vue' /* webpackChunkName: "pages/events/_eid/tickets" */))
const _edb425d4 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _ea46e344,
    name: "index___en"
  }, {
    path: "/ru",
    component: _ea46e344,
    name: "index___ru"
  }, {
    path: "/en/cart",
    component: _486be344,
    name: "cart___en"
  }, {
    path: "/en/categories",
    component: _7294e47a,
    name: "categories___en"
  }, {
    path: "/en/cookies",
    component: _61ab56ca,
    name: "cookies___en"
  }, {
    path: "/en/events",
    component: _9d1c1300,
    name: "events___en"
  }, {
    path: "/en/login",
    component: _54843175,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _edd9f4a4,
    name: "logout___en"
  }, {
    path: "/en/payment",
    component: _17590195,
    name: "payment___en"
  }, {
    path: "/en/profile",
    component: _53a86978,
    name: "profile___en"
  }, {
    path: "/en/register",
    component: _01c87747,
    name: "register___en"
  }, {
    path: "/en/restore",
    component: _075e981d,
    name: "restore___en"
  }, {
    path: "/en/search",
    component: _7c7416cc,
    name: "search___en"
  }, {
    path: "/en/widget",
    component: _90cf942a,
    name: "widget___en"
  }, {
    path: "/ru/cart",
    component: _486be344,
    name: "cart___ru"
  }, {
    path: "/ru/categories",
    component: _7294e47a,
    name: "categories___ru"
  }, {
    path: "/ru/cookies",
    component: _61ab56ca,
    name: "cookies___ru"
  }, {
    path: "/ru/events",
    component: _9d1c1300,
    name: "events___ru"
  }, {
    path: "/ru/login",
    component: _54843175,
    name: "login___ru"
  }, {
    path: "/ru/logout",
    component: _edd9f4a4,
    name: "logout___ru"
  }, {
    path: "/ru/payment",
    component: _17590195,
    name: "payment___ru"
  }, {
    path: "/ru/profile",
    component: _53a86978,
    name: "profile___ru"
  }, {
    path: "/ru/register",
    component: _01c87747,
    name: "register___ru"
  }, {
    path: "/ru/restore",
    component: _075e981d,
    name: "restore___ru"
  }, {
    path: "/ru/search",
    component: _7c7416cc,
    name: "search___ru"
  }, {
    path: "/ru/widget",
    component: _90cf942a,
    name: "widget___ru"
  }, {
    path: "/en/profile/tickets",
    component: _10ad998d,
    name: "profile-tickets___en"
  }, {
    path: "/en/widget/description",
    component: _c6b2f156,
    name: "widget-description___en"
  }, {
    path: "/ru/profile/tickets",
    component: _10ad998d,
    name: "profile-tickets___ru"
  }, {
    path: "/ru/widget/description",
    component: _c6b2f156,
    name: "widget-description___ru"
  }, {
    path: "/en/categories/:cid",
    component: _50f6a8a1,
    name: "categories-cid___en"
  }, {
    path: "/en/events/:eid",
    component: _53453b06,
    name: "events-eid___en"
  }, {
    path: "/en/groups/:id?",
    component: _6dc6a6a3,
    name: "groups-id___en"
  }, {
    path: "/en/payment/:hash",
    component: _55b54d30,
    name: "payment-hash___en"
  }, {
    path: "/en/restore/:key",
    component: _9f798fb6,
    name: "restore-key___en"
  }, {
    path: "/ru/categories/:cid",
    component: _50f6a8a1,
    name: "categories-cid___ru"
  }, {
    path: "/ru/events/:eid",
    component: _53453b06,
    name: "events-eid___ru"
  }, {
    path: "/ru/groups/:id?",
    component: _6dc6a6a3,
    name: "groups-id___ru"
  }, {
    path: "/ru/payment/:hash",
    component: _55b54d30,
    name: "payment-hash___ru"
  }, {
    path: "/ru/restore/:key",
    component: _9f798fb6,
    name: "restore-key___ru"
  }, {
    path: "/en/events/:eid/tickets",
    component: _c74780ca,
    name: "events-eid-tickets___en"
  }, {
    path: "/ru/events/:eid/tickets",
    component: _c74780ca,
    name: "events-eid-tickets___ru"
  }, {
    path: "/en/:slug",
    component: _edb425d4,
    name: "slug___en"
  }, {
    path: "/ru/:slug",
    component: _edb425d4,
    name: "slug___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
